









































































































































































































































enum STEP {
  NOTIFICATION = 1,
  SEND = 2,
}

import { cloneDeep, rest, truncate } from 'lodash';

import Vue from 'vue';
import UserLookup from './UserLookup.vue';
import {
  INotificationRecipients,
  Notification,
  NotificationRecipients,
} from '@/models/notification';
import { mapActions, mapState } from 'vuex';
import { ErrorManager } from '@/models/error';

export default Vue.extend({
  components: { UserLookup },
  name: 'StaffNotificationForm',

  data: () => ({
    STEP,
    valid: true,
    useScheduledTime: false,
    useSelectedEmployees: false,
    dialog: false,
    enableEdit: false,
    loading: false,

    errorMessage: '',

    value: STEP.NOTIFICATION,
    notification: new Notification(),
    textRules: [
      (v: any) => !!v || 'Field is required',
      (v: any) =>
        (v && v.length <= 256) || 'Field must be less than 256 characters',
    ],
  }),

  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['account']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
  },

  methods: {
    ...mapActions({
      createNotification: 'notification/createNotification',
      updateNotification: 'notification/updateNotification',
      fetchUsers: 'user/fetchUsers',
    }),

    truncate,

    open(notification: Notification, enableEdit = false) {
      this.dialog = true;
      this.enableEdit = enableEdit;

      if (enableEdit) {
        this.notification = cloneDeep(notification);

        this.notification.recipients = this.convertRecipientObjectstoIntList(
          this.notification.recipients!
        );
      } else {
        this.$nextTick(() => {
          this.reset();
        });
      }
    },

    close() {
      this.dialog = false;
      this.reset();
    },

    convertRecipientObjectstoIntList(
      recipients: INotificationRecipients[] | number[] | any
    ): number[] {
      const recipentList = [] as any;

      for (const key in recipients) {
        const userPK = recipients[key].user.pk;
        recipentList.push(userPK);
      }
      return recipentList;
    },

    async sendMessage(notification: {
      pk?: string;
      title: string;
      message: string;
      recipients: [];
    }) {
      this.loading = true;
      const recipientList = !this.useSelectedEmployees
        ? this.getAllUserObjectList()
        : this.convertIntListToUserObjectList(notification.recipients);

      if (this.enableEdit) {
        await this.updateNotification({
          pk: notification.pk,
          title: notification.title,
          message: notification.message,
          recipients: recipientList,
          franchise: this.account.franchise.pk,
        })
          .then(() => {
            this.$emit('successfull');
            this.close();
          })
          .catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        await this.createNotification({
          title: notification.title,
          message: notification.message,
          recipients: recipientList,
          franchise: this.account.franchise.pk,
        })
          .then(() => {
            this.$emit('successfull');
            this.close();
          })
          .catch((error: any) => {
            this.errorMessage = ErrorManager.extractApiError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    convertIntListToUserObjectList(
      recipients: number[]
    ): NotificationRecipients[] {
      const recipentList = [] as any;

      for (const key in recipients) {
        const userObj = { user: recipients[key] };
        recipentList.push(userObj);
      }
      return recipentList;
    },

    getAllUserObjectList(): NotificationRecipients[] {
      const recipentList = [] as any;

      for (const key in this.user) {
        const userObj = { user: this.user[key].pk };
        recipentList.push(userObj);
      }
      return recipentList;
    },

    reset() {
      (this.$refs.form as any).reset();
      (this.$refs.form as any).resetValidation();
      this.errorMessage = '';
    },
  },
  mounted() {
    this.loading = true;
    this.fetchUsers()
      .catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
});
