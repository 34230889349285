







































































































import { Notification } from '@/models/notification';
import dateFormat from '@/utils/dateFormat';
import Vue, { PropType } from 'vue';
export default Vue.extend({
  props: {
    notifications: {
      type: [] as PropType<Notification[]>,
    },
  },
  methods: {
    dateFormat,

    openDialog(notification: Notification) {
      this.$emit('openDialog', notification, true);
    },

    selectedNotification(notification: Notification) {
      this.$emit('selectedNotification', notification);
    },
  },
});
