


















































































































































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { ErrorManager } from '@/models/error';
import StaffNotificationForm from './StaffNotificationForm.vue';
import { INotification, Notification } from '@/models/notification';
import dateFormat from '@/utils/dateFormat';
import StaffNotificationManagerMobile from './StaffNotificationManagerMobile.vue';
import { searchItems } from '@/utils/functions';
import PackageRequestDialog from './PackageRequestDialog.vue';

export default Vue.extend({
  components: {
    StaffNotificationForm,
    StaffNotificationManagerMobile,
    PackageRequestDialog,
  },
  name: 'StaffNotification',

  data: () => ({
    snackbar: false,
    loading: false,
    deleteNotificationDialog: false,

    errorMessage: '',
    search: '',

    selectedNotificationData: new Notification(),
    notificationList: [] as Notification[],

    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: '',
        sortable: false,
        align: 'start',
        width: '20px',
        value: 'data-table-space',
      },
      {
        text: 'Title',
        value: 'title',
      },
      {
        text: 'Message',
        value: 'message',
      },
      {
        text: 'recipients',
        value: 'recipients',
      },
      {
        text: 'Created At',
        value: 'created_at',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
  }),

  computed: {
    ...mapState('notification', ['notification']),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },

    screenWidth() {
      return window.innerWidth > 600;
    },
  },

  watch: {
    search: {
      handler(value) {
        //if mobile view
        if (!this.screenWidth) {
          this.notificationList = searchItems<Notification>(
            this.notification,
            value
          );
        }
      },
    },
  },

  methods: {
    dateFormat,

    ...mapActions({
      fetchNotifications: 'notification/fetchNotifications',
      deleteNotification: 'notification/deleteNotification',
    }),

    openDialog(item: INotification, editForm = false) {
      if (editForm) {
        (this as any).$refs.staffnotificationform.open(item, editForm);
      } else {
        (this as any).$refs.staffnotificationform.open(item);
      }
    },

    openPackageRequestDialog() {
      (this as any).$refs.packagerequestdialog.open();
    },

    selectedNotification(item: Notification) {
      this.selectedNotificationData = item;
      this.deleteNotificationDialog = true;
    },

    removeNotification() {
      this.loading = true;
      this.deleteNotification(this.selectedNotificationData)
        .then(() => {
          this.genNotifications();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
          this.deleteNotificationDialog = false;
        });
    },
    genNotifications() {
      this.loading = true;
      this.fetchNotifications()
        .then(() => {
          this.notificationList = this.notification;
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.genNotifications();
  },
});
